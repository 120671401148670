import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addToViewedProducts,
  addToViewedProductsAsync,
  addToWishlist,
  getAllProducts,
} from "../features/products/productSlice";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Container from "./Container";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { MdOutlineShoppingBag, MdArrowBack, MdArrowForward } from "react-icons/md";

function countWords(text) {
  return text.split(/\s+/).filter((word) => word !== "").length;
}

// Shuffle array function
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const Bestsellers = () => {
  const productState = useSelector((state) => state.product.product);
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isTitleTooLong = productState?.some((item) => countWords(item?.title) > 10);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scrollRef = useRef(null);

  useEffect(() => {
    getallProducts();
  }, []);

  const getallProducts = () => {
    dispatch(getAllProducts());
  };

  const getCustomerfromLocalStorage = localStorage.getItem("customer")
    ? JSON.parse(localStorage.getItem("customer"))
    : null;

  const userId = getCustomerfromLocalStorage ? getCustomerfromLocalStorage._id : null;

  const handleProductView = async (productId, productLink) => {
    try {
      // Check if user is logged in before dispatching action
      if (userId) {
        await dispatch(addToViewedProductsAsync({ id: userId, productId }));
      }
  
      const recentlyViewed = localStorage.getItem("recentlyViewed") || "[]";
      const viewedProducts = JSON.parse(recentlyViewed);
  
      if (!viewedProducts.includes(productId)) {
        viewedProducts.unshift(productId);
      }
  
      if (viewedProducts.length > 10) {
        viewedProducts.pop(); // Limit the array size to 10
      }
  
      localStorage.setItem("recentlyViewed", JSON.stringify(viewedProducts));
  
      // Navigate using window.location.href
      window.location.href = productLink;
    } catch (error) {
      console.error("Error viewing product:", error);
    }
  };

  // Shuffle the products for random display
  const shuffledProducts = shuffleArray([...productState || []]);

  const PrevArrow = ({   onClick }) => {
    return (
      <div
        className="prev-arrow"
         onClick={onClick}
      >
        <MdArrowBack   className="arrow-icon" />
      </div>
    );
  };

  const NextArrow = ({  onClick }) => {
    return (
      <div
        className="next-arrow"
         onClick={onClick}
      >
        <MdArrowForward  className="next-arrow-icon" />
      </div>
    );
  };
  
    const settings = {
      dots: isMobile ? false : true,
      infinite: true,
      speed: 700,
      slidesToShow: isMobile ? 1 : 4,
      slidesToScroll: isMobile ? 1 : 4,
      prevArrow: <PrevArrow />,  
      nextArrow: <NextArrow />, 
      autoplay: isMobile ? true : false,
      autoplaySpeed: isMobile ? 3000 : 4000,
    };

  return (
    <>
      <Container class1="bestseller-wrapper home-wrapper-2">

        <div className={`bestseller-collection${isDesktop ? " bestseller" : ""}`}>
        <Slider {...settings}>
          {isDesktop
            ? shuffledProducts
                .filter((item) => item.tags === "featured")
                .slice(0, 10)
                .map((item, index) => {
                  const percentageDifference = Math.round(
                    ((item.previousPrice - item.price) / item.previousPrice) * 100
                  );
                  const productLink = getProductLink(item);

                  return (
                    <div key={index} className="col-3 px-0">
                      <div
                        className="product-card position-relative"
                        onClick={() => handleProductView(item?._id, productLink)}
                      >
                        <div className="product-image">
                          <img
                            src={item?.images[0]?.url}
                            className="img-fluid mx-auto"
                            alt="product"
                            width={160}
                            loading="lazy"
                            decoding="async" 
                            height={220}
                          />
                        <div className="button-container">

                        <button className="add-to-cart-btn" onClick={() => handleProductView(item?._id)}>
                      Add to Cart <MdOutlineShoppingBag className="bs-cart-icon"/>
                        </button>
                        </div>
                        </div>
                        <div className="percentage-difference">
                          -{percentageDifference}%
                        </div>
                        <div className="product-details">
                          <h6 className="brand">{item?.brand}</h6>
                          <h5 className="product-title">{item?.title}</h5>
                          <p className="price">KES {item?.price.toLocaleString()}</p>
                          <p className="price previous-price">
                            KES {item?.previousPrice.toLocaleString()}
                          </p>
 

                        </div>
                      </div>
                    </div>
                  );
                })
            : shuffledProducts.map((item, index) => {
                if (item.tags === "featured") {
                    const percentageDifference = Math.round(
                        ((item.previousPrice - item.price) / item.previousPrice) * 100
                      );
                      const productLink = getProductLink(item);

                  return (
                    <div key={index} className="col-3 px-0">
                      <div
                        className="product-card position-relative"
                        onClick={() => handleProductView(item?._id, productLink)}

                      >
                        <div className="product-image">
                          <img
                            src={item?.images[0]?.url}
                            className="img-fluid mx-auto"
                            alt="product"
                            width={160}
                            loading="lazy"
                            decoding="async" 
                            height={220}
                          />
            
                        </div>
                        <div className="percentage-difference">
                          -{percentageDifference}%
                        </div>
                        <div className="product-details">
                          <h6 className="brand">{item?.brand}</h6>
                          <h5 className="product-title">{item?.title}</h5>
                          <p className="price">KES {item?.price.toLocaleString()}</p>
                    
 
                          <button className="add-to-cart-btn" onClick={() => handleProductView(item?._id)}>
                      Add to Cart <MdOutlineShoppingBag className="bs-cart-icon"/>
                        </button>
                       
                        </div>
                        
                      </div>
                    </div>
                  );
                }
              })}
               </Slider>
        </div>
      </Container>
    </>
  );
};

export default Bestsellers;


function getProductLink(product) {
    return "/product/" + product._id;
  }