import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addToViewedProducts,
  addToViewedProductsAsync,
  addToWishlist,
  getAllProducts,
} from "../features/products/productSlice";
import { useNavigate } from "react-router-dom";
import Container from "./Container";
import { useMediaQuery } from "react-responsive";

function countWords(text) {
  return text.split(/\s+/).filter((word) => word !== "").length;
}

// Shuffle array function
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const FeaturedCollection = () => {
  const productState = useSelector((state) => state.product.product);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ minWidth: 768 });

  useEffect(() => {
    dispatch(getAllProducts());
  }, [dispatch]);

  const getCustomerfromLocalStorage = localStorage.getItem("customer")
    ? JSON.parse(localStorage.getItem("customer"))
    : null;

  const userId = getCustomerfromLocalStorage ? getCustomerfromLocalStorage._id : null;

  const handleProductView = async (productId, productLink) => {
    try {
      // Check if user is logged in before dispatching action
      if (userId) {
        await dispatch(addToViewedProductsAsync({ id: userId, productId }));
      }
  
      const recentlyViewed = localStorage.getItem("recentlyViewed") || "[]";
      const viewedProducts = JSON.parse(recentlyViewed);
  
      if (!viewedProducts.includes(productId)) {
        viewedProducts.unshift(productId);
      }
  
      if (viewedProducts.length > 10) {
        viewedProducts.pop(); // Limit the array size to 10
      }
  
      localStorage.setItem("recentlyViewed", JSON.stringify(viewedProducts));
  
      // Navigate using window.location.href
      window.location.href = productLink;
    } catch (error) {
      console.error("Error viewing product:", error);
    }
  };
  // Shuffle the products for random display
  const shuffledProducts = shuffleArray([...productState || []]);

  return (
    <Container class1="featured-wrapper home-wrapper-2">
      <div className="featured-collection featured">
        {shuffledProducts
          .filter((item) => item.tags === "featured")
          .slice(0, 10)
          .map((item, index) => {
            const percentageDifference = Math.round(
              ((item.previousPrice - item.price) / item.previousPrice) * 100
            );

            const productLink = getProductLink(item);


            return (
              <div key={index} className={`px-0 ${isDesktop? "col-3":"search-card"}`}>
                <div
                  className="product-card position-relative"
                  onClick={() => handleProductView(item?._id, productLink)}
                >
                  <div className="product-image">
                    <img
                      src={item?.images[0]?.url}
                      className="img-fluid mx-auto"
                      alt="product"
                      width={160}
                      loading="lazy"
                      height={220}
                      decoding="async" 
                    />
                  </div>
                  <div className="percentage-difference">
                    -{percentageDifference}%
                  </div>
                  <div className="product-details">
                    <h6 className="brand">{item?.brand}</h6>
                    <h5 className="product-title">{item?.title}</h5>
                    <p className="price">KES {item?.price.toLocaleString()}</p>
                    <div className="button-container">
                      <p className="price previous-price">
                        KES {item?.previousPrice.toLocaleString()}
                      </p>
                      <button onClick={() => handleProductView(item?._id)}>
                        View
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </Container>
  );
};

export default FeaturedCollection;


function getProductLink(product) {
    return "/product/" + product._id;
  }