import React, { useEffect, useState } from "react";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { getAProduct, getAllProducts } from "../features/products/productSlice";
import { getUserCart, logoutUser } from "../features/user/userSlice";
import {AiOutlineMenu} from "react-icons/ai"
import { MediaSidebarData } from './MediaSidebarData';
import MediaSidebarItem from './MediaSidebarItem';
import {RxCross2} from "react-icons/rx"
import AdvertisementBar from "./AdvertisementBar";
import {FaUserCheck} from "react-icons/fa";
import {FaUser} from "react-icons/fa"

import {TiShoppingCart} from "react-icons/ti"
import Logins from "./Logins";
import axios from "axios";
import { base_url } from "../utils/axiosConfig";
import logo from "../images/logo-x.png"


const Header = () => {
  const dispatch = useDispatch();
  const cartState = useSelector(state => state?.auth?.cartProducts)
  const authState = useSelector(state => state?.auth)
  const wineState = useSelector((state) => state?.wines?.wines);
  const productState = useSelector(state => state?.product?.product)
  const [productOpt,setProductOpt]=useState([])
  const [paginate, setPaginate] = useState(true);
const navigate=useNavigate()
  const [total, setTotal] = useState(0)
  const getTokenFromLocalStorage = localStorage.getItem("customer")
  ? JSON.parse(localStorage.getItem("customer"))
  : null;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get('search');
 
 const config2 = {
  headers: {
    Authorization: `Bearer ${
      getTokenFromLocalStorage !== null ? getTokenFromLocalStorage.token : ""
    }`,
    Accept: "application/json",
  },
};
 
useEffect(() => {
  const interval = setInterval(() => {
    dispatch(getUserCart(config2));
  }, 1000);

  return () => clearInterval(interval); // Clear interval on unmount
}, [dispatch, config2]);

  useEffect(() => {
    let sum=0
    for (let index = 0; index < cartState?.length; index++) {
     sum=sum + (Number(cartState[index].quantity) * Number(cartState[index].price))
      setTotal(sum)
    }
  }, [cartState])

  useEffect(() => {
    const query = searchQuery ? searchQuery.toLowerCase() : '';
    const suggestions = new Set(productOpt);
  
    const allProducts = [...productState, ...wineState];
    allProducts.forEach(item => {
      const titleWords = item.title.toLowerCase().split(' ');
  
      const brand = item.brand ? item.brand.toLowerCase() : '';
  
      if (brand.includes(query)) {
        suggestions.add(brand);
      }
      if (item.title.toLowerCase().includes(query)) {
        suggestions.add(item.title);
      }
      if (item.type && item.type.toLowerCase().includes(query)) {
        suggestions.add(item.type);
      }
      if (item.category && item.category.toLowerCase().includes(query)) {
        suggestions.add(item.category);
      }
  
      titleWords.forEach(word => {
        if (word.includes(query)) {
          suggestions.add(word);
        }
      });
    });
  
    // Update productOpt only when necessary
    setProductOpt(Array.from(suggestions));
  }, [productState, wineState, searchQuery]);
  
  const handleSuggestionSelect = (selected) => {
    if (selected.length > 0) {
      const selectedSuggestion = selected[0];
      navigate(`/product?search=${selectedSuggestion}`);
    }
  };


  

  const showSideBar = () => {
    setSidebar(!sidebar);
    document.body.classList.toggle('sidebar-active', !sidebar);
  };
  
  const [sidebar, setSidebar] = useState(false);



  return (
    <>

    <div className="w-100 advertisement-bar">
      <AdvertisementBar/>
    </div>
      <header className="header-top-strip ">
        <div className="container-xxl">
          <div className="row free-shipping">
            <div className="col-6">
              <p className="text-white mb-0">
                Free Shipping Over KES 100,000 & Free Returns
              </p>
            </div>
            <div className="col-6">
              <p className="text-end text-white mb-0">
                Hotline:
                <a className="text-white" href="tel:+254 113886482">
                  +254 113886482
                </a>
              </p>
            </div>
          </div>

        </div>
      </header>
      <header className="header-upper py-3 fixed-header">
        <div className="container-xxl">
          <div className="row  align-items-center">
            <div className="col-2 h-upper-links">
              <div className="electro-logo d-flex align-items-center">

              <AiOutlineMenu className="menu-btn" onClick={showSideBar}/>

   
              <div className={sidebar ? 'sidebar active' : 'sidebar'}>
  <div className='sidebar-header'>
    Best Sellers
    <RxCross2 className='cross-btn'onClick={showSideBar}/>
    
  </div>
       {MediaSidebarData.map((item,index) =>  <MediaSidebarItem key={item.id} item={item} onClick={showSideBar}/>)}
      </div>
      {sidebar && <div className="overlay" onClick={showSideBar}></div>}

              <Link to="/" className="logo-img-container">
              <img src={logo} loading="lazy" alt="Best Sellers Logo"
              decoding="async" 
              width={30}
              height={40}/>
                <h1>The Best Sellers Shop</h1>
              </Link>
              </div>
              <div className="col-5 h-upper-links2">
              <div className="header-upper-links2 gap-10  d-flex align-items-center ">
        
  
              <div className="logins">
              <Link
                to={authState?.user ? "/my-account" : "/login"}
                className="d-flex login-container align-items-center text-white"
              >
                {authState?.user ? (
                  <>
                    <p className="mb-0 login-auth">{authState.user.firstname}</p>
                    <FaUserCheck className="login-icon" />
                  </>
                ) : (
                  <>
                    <p className="mb-0 login-auth">Log in</p>
                    <FaUser className="login-icon1" />
                  </>
                )}
              </Link>

              </div>

               
                  <Link
                    to="/cart"
                    className="d-flex cart-wrapper align-items-center position-relative  text-white"
                  >
                    <TiShoppingCart className="cart-icon"/>
                    <div className="d-flex badge-container">
                      <span className="badge bg-white text-dark">{cartState && cartState?.length ? cartState?.length : 0}</span>
                    </div>
                  </Link>
              
              </div>
            </div>
            </div>
            <div className="col-5 search-el">
              <div className="input-group">
              <Typeahead
                id="pagination-example"
                onPaginate={() => console.log('Results paginated')}
                onChange={handleSuggestionSelect} // Handle suggestion selection
                options={productOpt}
                paginate={paginate}
                labelKey={"name"}
                minLength={2}
                placeholder="what are you looking for?"
              />

                <span className="input-group-text " id="basic-addon2">
                  Search
                </span>
              </div>
            </div>
            <div className="col-5 h-upperlinks1">
              <div className="header-upper-links d-flex align-items-center justify-content-end">
 
             
           <Logins/>

              
                <div className="cart-el">
                  <Link
                    to="/cart"
                    className="d-flex align-items-center position-relative  text-white"
                  >
                    <TiShoppingCart className="cart-icon"/>
                    <div className="d-flex badge-container gap-10">
                      <span className="badge bg-white text-dark rounded-circle">{cartState && cartState?.length ? cartState?.length : 0}</span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <header className="header-bottom py-3">
        <div className="container-xxl">
          <div className="row">
            <div className="col-12">
              <div className="menu-bottom d-flex">
  
                <div className="menu-links">
                  <div className="d-flex align-items-center menu-links-nav">
                  <NavLink to="/new-arrivals">New Arrivals</NavLink>

                  <NavLink to="/best-sellers">Best Sellers</NavLink>
                    <NavLink to="/today's-deals">Today's Deals</NavLink>
                    <NavLink to="/contact">Contact Us</NavLink>

                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
