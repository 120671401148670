import React, { useEffect, useRef, useState } from "react";
import Marquee from "react-fast-marquee";
import Container from "../components/Container";
import { services } from "../utils/Data";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogs } from "../features/blogs/blogSlice";
import  {  getAllProducts } from "../features/products/productSlice";
import {  Link, useNavigate } from "react-router-dom";
import { addToWishlist } from "../features/products/productSlice";
import { useMediaQuery } from "react-responsive";
import "react-multi-carousel/lib/styles.css";
import { getAllCovers } from "../features/cover/coverSlice";
import { useParams } from 'react-router-dom';
import { getAllWines } from "../features/wines/wineSlice";
import Meta from "../components/Meta";
import { MdOutlineNavigateNext } from "react-icons/md";
import HeroSlider from "../components/HeroSlider";
import "../styles/collection-wrapper.css"
import "../styles/bestseller.css"
import "../styles/testimonial.css"
import office from "../images/office-2.jpg";
import FeaturedCollection from "../components/FeaturedCollection";
import Bestsellers from "../components/BestSeller";
import { RiDoubleQuotesL } from "react-icons/ri";
import officeDesk from "../images/office-desk-h.jpg"
import officeChair from "../images/office-chair.jpg"
import waitingChair from "../images/visitors-1.jpg"


function countWords(text) {
  return text.split(/\s+/).filter(word => word !== '').length;
}

const Home = () => {
  const [grid, setGrid] = useState(4);
  const productState = useSelector((state) => state.product.product);
  const navigate=useNavigate()
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isTitleTooLong = productState?.some(item => countWords(item?.title) > 10);
  const coverState = useSelector((state) => state.covers.covers);
  const recentlyViewed = useSelector((state) => state.product.recentlyViewed);

const { productId } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    getblogs();
    getallProducts();
    getallCovers();
    getAllWines();
  }, []);
  const getblogs = () => {
    dispatch(getAllBlogs());
  };
  const getallProducts = () => {
    dispatch(getAllProducts());
  };

  const getallCovers =()=>{
    dispatch(getAllCovers());
  }

  const addToWish = (id) => {
    dispatch(addToWishlist(id));
  };


  


  return (
    <>
<Meta
  title="The Best Sellers Shop | Office Furniture"
  description="Discover premium office furniture at The Best Sellers Shop. Ergonomic chairs, durable desks, and stylish storage solutions at affordable prices."
  keywords="office furniture, ergonomic chairs, durable desks, storage solutions"
  canonical="https://bestsellersshopke.com/"
  ogImage="https://bestsellersshopke.com/logo-x.png"  
/>

<hr/>

<Container class1="home-wrapper-1 py-2" >
<div className="hero-wrapper">
          <div>
            <HeroSlider/>
          </div>
          <div className="hero-intro">
          <h2>Elegant Office Furniture for Modern Workspaces</h2>
          <p>
            Transform your workspace with our premium selection of office furniture. 
            
          </p>
          <Link
            to="/products"
            className="shop-now-button"
  
          >
            Explore Our Collection
          </Link>
        </div>
         </div>
      </Container>

     


<hr/>

<Container class1="home-wrapper-1 py-2" >
        <div className="d-flex cover-wrapper" >
        <div className='cover'>
            <div className="cover-overlay"></div>
            <img
             loading="lazy"
              src={officeChair}
              alt="Office Chairs"
              width={250}
              height={400}
              decoding="async" 
            />
            <div className="cover-content">
              <h2>Office Chairs</h2>
              <Link to="/office-chair" className="cover-button">
                Shop Now <MdOutlineNavigateNext />
              </Link>
            </div>
          </div>
          <div className='cover'>
          <div className="cover-overlay"></div>
          <img
           loading="lazy"
              src={officeDesk}
              alt="Office Desks"
              width={250}
              height={400}
              decoding="async" 
            />
            <div className="cover-content">
              <h2>Office Desks</h2>
              <Link to="/office-desk" className="cover-button">
                Shop Now <MdOutlineNavigateNext />
              </Link>
            </div>
          </div>
          <div className='cover'>
          <div className="cover-overlay"></div>
            <img
            loading="lazy"
              src="https://furniturechoicekenya.com/wp-content/uploads/2023/06/H1df601b419b74034ba71091253a67d16M.jpg_960x960.webp"
              alt="Boardroom Tables"
              width={250}
              height={400}
              decoding="async" 
            />
            <div className="cover-content">
              <h2>Boardroom Tables</h2>
              <Link to="/boardroom-table" className="cover-button">
                Shop Now <MdOutlineNavigateNext />
              </Link>
            </div>
          </div>
          <div className='cover'>
        <div className="cover-overlay"></div>
        <img 
      loading="lazy"   
      src={waitingChair} alt="Waiting Chairs" 
      width={250}
      height={400}
      decoding="async" 
      />
        <div className="cover-content">
         <h2 >Waiting Chairs</h2>
         <Link to="/waiting-chair" className="cover-button"> Shop Now <MdOutlineNavigateNext /></Link>
       </div>
        </div>
  
        </div>
      </Container>
 
      <Container class1="home-wrapper-1 py-2" >
        <div className="collection-wrapper">

         <h2>Featured Collection</h2>
 
          
            <FeaturedCollection/>
   
        </div>
      </Container>
        
      <Container class1="home-wrapper-1 py-2" >
        <div className="best-sellers-collection">
           <div className="best-seller-header">
            <h2>Bestseller</h2>
            <p>Experience the best products at our store!</p>
           </div>
            <div className="best-seller-products">
            <Bestsellers/>
            </div>
          </div>  
       </Container>
    
       <Container class1="home-wrapper-1 py-2">
  <div className="testimonial-wrapper">
    <div className="left-col-el">
      <h2>Testimonials</h2>
      <div className="testimonial-content">
      <RiDoubleQuotesL className="testimonial-icon"/>
        <p>
          The furniture selection here is outstanding! I was able to find exactly
          what I needed for my office setup. The quality and design exceeded my
          expectations.
        </p>
        
        <span className="testimonial-author">DARWIN - Nairobi</span>

      </div>
    </div>
    <div className="right-col-el">
      <img
        src={office}
        alt="Furniture"
        className="testimonial-image"
        loading="lazy"
        width={525}
        height={350}
      />
    </div>
  </div>
</Container>


      <Container class1="home-wrapper-2 service-wrapper">
  <div className="row">
    <div className="col-12">
      <div className="servies d-flex align-items-center justify-content-between">
        <Marquee>
          {services?.map((i, j) => {
            return (
              <div
                className="services-card d-flex align-items-center gap-15"
                key={j}
              >
                {typeof i.image === "string" ? (
                  <img src={i.image} alt="services" 
                  decoding="async" 
                  width={30}
                  height={30}/>
                ) : (
                  i.image
                )}
                <div className="services-content">
                  <h6>{i.title}</h6>
                  <p className="mb-0">{i.tagline}</p>
                </div>
              </div>
            );
          })}
        </Marquee>
      </div>
    </div>
  </div>
</Container>

      
      <hr/>




    </>
  );
};

export default Home;
